import FeedbackTour from 'src/components/FeedbackTour/FeedbackTour';
import { capitalizeFirstLetter } from 'src/utils/string';

const dashboardPageSteps = (intl, resources) => {

  const feature = {
    id: 1,
    descricao: 'dashboardIndividual',
  };
  
  return [
    {
      selector: '.iniciando-tour',
      content: capitalizeFirstLetter(
        intl.formatMessage({ id: 'iniciandoTour' })
      ),
    },
    {
      selector: '.dash-step1',
      content: capitalizeFirstLetter(
        intl.formatMessage({ id: 'visualizeValoresPendentes' })
      ),
    },
    {
      selector: '.dash-step2',
      content: capitalizeFirstLetter(
        intl.formatMessage(
          { id: 'visualizeAcoesAtrasadas' },
          { acoes: resources.acoes }
        )
      ),
    },
    {
      selector: '.dash-step3',
      content: capitalizeFirstLetter(
        intl.formatMessage({ id: 'visualizeARelatoriosPendentes' })
      ),
    },
    {
      selector: '.dash-step4',
      content: capitalizeFirstLetter(
        intl.formatMessage(
          { id: 'configuracoesExibicao' },
          { acoes: resources.acoes }
        )
      ),
    },
    {
      selector: '.dash-step5',
      content: capitalizeFirstLetter(
        intl.formatMessage(
          { id: 'visualizeProjetosAtivos' },
          { projetos: resources.projetos }
        )
      ),
    },
    {
      selector: '.dash-step6',
      content: capitalizeFirstLetter(
        intl.formatMessage(
          { id: 'visualizeIndicadoresAtivos' },
          { indicadores: resources.indicadores }
        )
      ),
    },
    {
      selector: '.dash-step7',
      content: capitalizeFirstLetter(
        intl.formatMessage(
          { id: 'visualizeAcoesAreaUsuario' },
          { acoes: resources.acoes, area: resources.area }
        )
      ),
    },
    {
      selector: '.dash-step8',
      content: capitalizeFirstLetter(
        intl.formatMessage(
          { id: 'visualizeAcoesDeAcordoComFarol' },
          { acoes: resources.acoes }
        )
      ),
    },
    {
      selector: '.dash-step9',
      content: capitalizeFirstLetter(
        intl.formatMessage(
          { id: 'exibirDasboards' },
          { dashboards: resources.dashboards }
        )
      ),
    },
    {
      selector: '.dash-step10',
      content: capitalizeFirstLetter(
        intl.formatMessage(
          { id: 'ordeneFavoriteDasboards' },
          { dashboards: resources.dashboards }
        )
      ),
    },
    {
      selector: '.dash-step11',
      content: capitalizeFirstLetter(
        intl.formatMessage(
          { id: 'exibirMapasEstrategicos' },
          { mapasestrategicos: resources.mapasestrategicos }
        )
      ),
    },
    {
      selector: '.dash-feedback',
      content: <FeedbackTour feature={feature} intl={intl} />,
    },
  ];
};

export default dashboardPageSteps;
