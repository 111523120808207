import React, { useState, useEffect } from 'react';

import Button from 'src/componentes/button';
import Input from 'src/componentes/input';
import LoadingContainer from 'src/componentes/loading-container';
import http from 'src/services/httpService';
import { capitalizeFirstLetter } from 'src/utils/string';
import { useTour } from '@reactour/tour';

const FeedbackTour = ({ feature, intl }) => {
  const { setIsOpen } = useTour();
  const [feedback, setFeedback] = useState(null);
  const [comment, setComment] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [jaExisteFeedbackDash, setJaExisteFeedbackDash] = useState(null);
  const [jaExisteFeedbackMatriz, setJaExisteFeedbackMatriz] = useState(null);
  const color = '#f6f9fc';
  const isLoadingFeedback = jaExisteFeedbackDash === null || jaExisteFeedbackMatriz === null;

  useEffect(() => {
    getFeedbackTour();
  }, []);

  const getFeedbackTour = async () => {
    try {
      var response = await http.post(
        'FeedbackTour/ObterFeedbackPorColaborador'
      );
      setJaExisteFeedbackDash(response.data.value.jaExisteFeedbackDash);
      setJaExisteFeedbackMatriz(response.data.value.jaExisteFeedbackMatriz);
    } catch (err) {}
  };

  const handleLike = () => {
    setFeedback('liked');
  };

  const handleDislike = () => {
    setFeedback('disliked');
  };

  const handleSaveFeedback = async () => {
    setIsLoading(true);
    setIsOpen(false);

    const model = {
      feature,
      avaliacao:
        feedback === 'liked'
          ? { id: 1, descricao: 'Sim' }
          : { id: 2, descricao: 'Não' },
      mensagem: comment,
    };

    try {
      await http.post('FeedbackTour/SalvarFeedbackTour', {
        model: model,
      });
      setIsLoading(false);
      setFeedback(null);
      setComment('');
    } catch (err) {
      setIsLoading(false);
    }
  };

  const isTourEnded =
    (location.pathname === '/painel/dashboard-individual' &&
      jaExisteFeedbackDash) ||
    (location.pathname === '/acompanhar/matriz-resultado' &&
      jaExisteFeedbackMatriz);

  return (
    <div style={{ maxWidth: '1000px', margin: '0 auto' }}>
      <div>
        <LoadingContainer isLoading={isLoading}>
          <div
            className="d-flex flex-column align-items-center justify-content-center px-3 font-weight-bolder"
            formStyle={{ width: '145%' }}
          >
            {!isLoadingFeedback && (isTourEnded ? (
              <p className="font-weight-bolder" style={{ fontSize: '1rem' }}>
                {capitalizeFirstLetter(
                  intl.formatMessage({ id: 'tourAcabou' })
                )}
              </p>
            ) : (
              <>
                <p className="font-weight-bolder" style={{ fontSize: '1rem' }}>
                  {capitalizeFirstLetter(
                    intl.formatMessage({ id: 'tourGuiadoFoiUtil' })
                  )}
                </p>
                <div
                  className="pb-4"
                  style={{
                    marginRight: 5,
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                   <Button
                    color={color}
                    className="tour-button"
                    style={{
                      fontSize: '1.2rem',
                      padding: '15px',
                      margin: '4px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      borderColor: feedback === 'liked' ? '#dde6e9' : '#fff', 
                      borderRadius: '0.2rem',
                      width: '80px',
                      height: '15px'
                    }}
                    onClick={handleLike}
                  >
                    👍
                    <span
                      style={{
                        fontSize: '0.9rem',
                      }}
                    >
                      {capitalizeFirstLetter(intl.formatMessage({id: 'label.sim'}))}
                    </span>
                  </Button>
                  <Button
                    color={color}
                    className="tour-button"
                    style={{
                      fontSize: '1.2rem',
                      padding: '15px',
                      margin: '4px',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      borderColor: feedback === 'disliked' ? '#dde6e9' : '#fff',
                      width: '80px',
                      height: '15px'
                    }}
                    onClick={handleDislike}
                  >
                    👎
                    <span
                      style={{
                        fontSize: '0.9rem',
                      }}
                    >
                      {capitalizeFirstLetter(intl.formatMessage({id: 'label.nao'}))}
                    </span>
                  </Button>
                </div>
                <Input
                  className="mt-2 px-4"
                  showLabel
                  formStyle={{ width: '100%' }}
                  type="textarea"
                  rows={2}
                  maxLength={1000}
                  model={{
                    label: capitalizeFirstLetter(
                      intl.formatMessage({ id: 'acrescenteMotivosAvaliacao' })
                    ),
                    value: comment,
                    requestChange: (value) => setComment(value),
                  }}
                />
                <Button
                  className="mt-4"
                  onClick={handleSaveFeedback}
                  disabled={!feedback || isLoading}
                >
                  {capitalizeFirstLetter(
                    intl.formatMessage({ id: 'enviarFeedback' })
                  )}
                </Button>
              </>
            ))}
          </div>
        </LoadingContainer>
      </div>
    </div>
  );
};

export default FeedbackTour;
