import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Row, Col } from 'reactstrap';

import useAuth from 'src/hooks/useAuth';

import Drawer from 'src/componentes/drawer';
import { capitalizeFirstLetter } from 'src/utils/string';
import LoadingContainer from 'src/componentes/loading-container';
import { MODULOS } from 'src/utils/constants';
import PanelCollapse from 'src/componentes/panel-collapse';
import TourController from 'src/components/TourController/TourController';
import dashboardPageSteps from 'src/tours/DashboardPageSteps';
import matrizPageSteps from 'src/tours/MatrizResultadosSteps';

const HelpDrawer = ({
  openHelp,
  setOpenHelp,
  module,
  podeVisualizarMatriz,
}) => {
  const intl = useIntl();
  const { terms: resources } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const dashSteps = dashboardPageSteps(intl, resources);
  const matrizSteps = matrizPageSteps(intl, resources);

  const handleClose = () => {
    setOpenHelp(false);
  };

  const renderAll = () => (
    <>
      {module?.id == MODULOS.GESTAO_ESTRATEGICA && (
        <PanelCollapse
          md={12}
          open={true}
          header={capitalizeFirstLetter(
            intl.formatMessage({ id: 'label.dashboardIndividual' })
          )}
          headerStyle="text-primary"
          className="mb-3"
        >
          <div md={12}>
            {capitalizeFirstLetter(
              intl.formatMessage({ id: 'visualizeResumoCompleto' })
            )}
          </div>
          <div style={{ marginTop: '1rem' }}>
            <TourController
              setOpenHelp={setOpenHelp}
              redirectPath="/painel/dashboard-individual"
              steps={dashSteps}
            />
          </div>
        </PanelCollapse>
      )}
      {podeVisualizarMatriz && (
        <PanelCollapse
          md={12}
          open={true}
          header={capitalizeFirstLetter(resources.matrizderesultados)}
          headerStyle="text-primary"
          className="mb-3"
        >
          <div md={12}>
            {capitalizeFirstLetter(
              intl.formatMessage({ id: 'acompanheAtualizeValoresItens' })
            )}
          </div>
          <div style={{ marginTop: '1rem' }}>
            <TourController
              setOpenHelp={setOpenHelp}
              redirectPath="/acompanhar/matriz-resultado"
              steps={matrizSteps}
            />
          </div>
        </PanelCollapse>
      )}
    </>
  );

  return (
    <Drawer open={openHelp} close={handleClose}>
      <LoadingContainer isLoading={isLoading}>
        <Row>
          <Col md={12}>
            <div
              className="font-weight-bolder mb-2"
              style={{ fontSize: '22px' }}
            >
              {capitalizeFirstLetter(intl.formatMessage({ id: 'ajuda' }))}
            </div>
          </Col>
          {renderAll()}
        </Row>
      </LoadingContainer>
    </Drawer>
  );
};

export default HelpDrawer;
