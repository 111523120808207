import React from 'react';
import classnames from 'classnames';
import '@mdi/font/scss/materialdesignicons.scss';
import css from '../../utils/css';

const baseClasses = css`
  .container {
    display: inline-block;
    vertical-align: middle;
  }
`;

export default function MdiIcon(props) {
  let {
    children,
    className,
    icon,
    size = 16,
    color,
    colorHover,
    rotate,
    spin,
    title,
    ...other
  } = props;

  let classes = css`
    .container {
      font-size: ${size ? size + 'px' : 'inherit'};
      color: ${color || 'inherit'};
      transform: ${rotate ? rotate : 'inherit'};
      &:hover {
        color: ${colorHover || 'inherit'};
      }
    }
  `.extend(baseClasses);

  return (
    <div
      {...other}
      className={classnames(classes.container, className)}
      title={title}
      disabled={true}
    >
      <i className={`mdi mdi-${icon} ${spin && 'mdi-spin'}`}></i>
    </div>
  );
}
