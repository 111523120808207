import moment from 'moment';

import history from 'src/history';
import { userLoggedIn, fotoFetched } from 'src/actions/users';
import { languageAdd, languageRemove } from 'src/actions/language';
import { setSession } from 'src/contexts/AuthContext';

import http from './httpService';
import authStorage from './authStorage';

const afterLogin = (appInfoModel, redirect, external) => (dispatch) => {
  //appInfoModel.jwt && authStorage.setLocalJwt(appInfoModel.jwt, external);
  appInfoModel && authStorage.setAuthType(appInfoModel.authenticationType);
  dispatch(userLoggedIn(appInfoModel, external));

  if (
    redirect &&
    appInfoModel &&
    appInfoModel.tipoColaboradorAtual &&
    appInfoModel.tipoColaboradorAtual.id == 2 &&
    appInfoModel.primeiroAcesso
  ) {
    //primeiro acesso do Admin, deve alterar a senha por questoes de seguranca
    history.push(`/definir-senha?email=${appInfoModel.loginUsuario}`);
  }
};

export const logoutAdfs = (isApp) => {
  http
    .get('/Authentication/LogoutAdfs', { params: { isApp: isApp } })
    .then((response) => {
      if (response && response.data) window.location.href = response.data;
    })
    .catch((response) => {
      //history.push('/login');
    });
};

export const logout = (permanecerNaPagina, isApp) => async (dispatch) => {
  await http
    .get('/Home/Logout', { params: { isApp: isApp } })
    .then((response) => {
      authStorage.Logout();

      if (response.data.utilizandoAdfs == true) {
        logoutAdfs(isApp);
      } else {
        dispatch(userLoggedOut(response.data));

        if (!permanecerNaPagina) {
          history.push('/login');
          if (document.getElementsByClassName('grecaptcha-badge')[0])
            document.getElementsByClassName(
              'grecaptcha-badge'
            )[0].style.visibility = 'visible';
        }
      }
    })
    .catch((response) => {
      return Promise.reject(response.data || response);
    });
};

export const getApplicationInfo = (isApp) => (dispatch) => {
  return http
    .get('/Home/GetApplicationInfo', { params: { isApp: isApp } })
    .then((response) => {
      const jwt = {
        accessToken: response.data.jwt?.token,
        refreshToken: response.data.jwt?.refreshToken,
        tokenExpirationTime: response.data.jwt?.expiraTokenMinutos,
      };
      setSession(jwt, false);
      dispatch(userLoggedIn(response.data));
    });
};

export const recuperarSenha = (data) => (dispatch) => {
  return http.post('/Home/RecuperarSenha', data).then((response) => {
    afterLogin(response.data, true)(dispatch);
  });
};

export const definirSenha = (data) => (dispatch) => {
  return http.post('/Home/DefinirSenha', data).then((response) => {
    afterLogin(response.data, true)(dispatch);
    history.push('/login');
  });
};

export const getResources = (locale) => (dispatch) => {
  return http.post('/Home/GetResource', { locale }).then((response) => {
    dispatch(languageRemove(response.data.locale));
    dispatch(languageAdd(response.data));
    return response.data;
  });
};

export const getUsuarioLogado = () => (dispatch) => {
  return http.post('/Home/ObterUsuarioLogado').then((response) => {
    return response.data;
  });
};

export const changeUserPhoto = (fotoUltimaAlteracao) => (dispatch) => {
  dispatch(fotoFetched(fotoUltimaAlteracao));
};
